<template>
  <BaseModal
    name="modal-edit-domain"
    id="modal-edit-domain"
    idModal="modal-edit-domain"
    size="md"
    title="Editar homepage"
    @shown="
      (dominio = 'https://' + domain.domain),
      (homepage = domain.page)
    "
  >
    <div>
      <div>
        <label>Seu domínio</label>
        <div class="dominio" id="dominio">
          <input
            class="w-100"
            type="text"
            name="dominio"
            id="dominio"
            placeholder="seusite.com.br ou campanha.seusite.com.br"
            v-model="dominio"
            disabled="true"
          />
        </div>

        <div class="homepage mt-4">
          <div class="add-link-body">
            <div class="label-flex">
              <label for="Homepage">Homepage</label>
            </div>
            <router-link
              v-if="!pages.length && verificUserLevel && loadingPages == false"
              :to="{
                name: 'CampanhasLista',
              }"
              class="add-link link mb-0"
              >Deseja criar uma página?</router-link
            >
          </div>
          <BaseSelect
            :selectModel="homepage"
            placeholder="Selecione uma homepage"
            trackname="title"
            track-by="id"
            :array="pages"
            :searchable="true"
            :hide-selected="true"
            selectLabel=""
            deselectLabel=""
            selectedLabel=""
            :loading="loadingPages"
            @search="debouncePages"
            @callback="fetchPageListService($event)"
            @change="homepage = $event"
            :watch="true"
          >
          </BaseSelect>
        </div>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>

      <BaseButton @click="editarHomepage" variant="primary"
        >Salvar Homepage</BaseButton
      >
    </template>
  </BaseModal>
</template>
<script>
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

import Multiselect from "vue-multiselect";

import BaseButton from "@/components/BaseButton";

import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  props: ["domain"],
  data() {
    return {
      stop: false,
      dominio: "",
      homepage: { title: "Selecione uma homepage", id: 0 },
      pages: [],
      loadingPages: false,
      searchPages: "",
      stopPages: false,
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    debouncePages: _.debounce(function (query) {
      if (query === "") {
        this.loadingPages = false;
      } else {
        this.searchPages = query;
        // this.forms = [];
        this.fetchPageListService(1, query);
      }
    }, 500),

    async fetchPageListService(page = 1, query = "") {
      if (this.stopPages == true) {
        return;
      }

      this.loadingPages = true;

      var data = {
        status: "save",
        order_by: "title",
        order: "ASC",
        search: query,
        page: page,
      };

      await PaginaListService.create(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopPages = true;
          }

          this.pages = this.pages.concat(resp.data);
          this.pages = this.pages.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((err) => {})
        .finally(() => {
          this.loadingPages = false;
        });
    },

    editarHomepage() {
      let data = {
        page_id_default: this.homepage.id,
        domain_id: this.domain.id,
        id: " ",
      };

      serviceDomain
        .update(data)
        .then((resp) => {
          this.$bvToast.toast("Homepage do domínio atualizada com sucesso!", {
            title: "Domínios",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.$bvModal.hide("modal-edit-domain");
          this.$emit("update");
        })
        .catch((err) => {
          this.$bvToast.toast("Erro ao atualizar homepage", {
            title: "Domínios",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dominio {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dominio span {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 14px;
}
</style>
